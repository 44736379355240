<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('orders.detail')"
      :paths='pagePath()'
    />

    <client-section
      @onChange='onClientChange'
    />

    <vendor-section
      v-if='isVendor'
      @onChange='onVendorChange'
    />


    <products-section :vendor-id='getVendorId' v-if='isVendor' />

    <div class='order-detail-table'>
      <b-card
        no-body
        class='wameed-card' v-if='isVendor'
      >
        <b-card-header class='align-items-start tab-title'>
          <h5 class='text-med-18 text-font-main'>
            {{ $t('orders.detail') }}
          </h5>
        </b-card-header>

        <div class='w-table '>

          <order-table
            v-if='detail.order'
            :order='detail.order'
            :tableFields='detail.tableFields'
            :editOrder='true'
            @onCartEdit='onCartEdit'
          />

          <div class='p-5 d-flex justify-content-between'>
            <div></div>
            <div class='bill-detail px-4 '>
              <bill-price-tile
                v-if='detail.vendorId ||  true'
                :data='detail.bill.order'
                :title="$t('orders.bill.order')"
              />
              <template v-if='detail.bill'>


                <bill-price-tile
                  v-if='detail.vendorId'
                  :data='detail.bill.service'
                  :title="$t('orders.bill.service')"
                  type='service'
                />

                <bill-price-tile
                  :data='detail.bill.delivery'
                  :title="$t('orders.bill.delivery')"
                />
                <bill-price-tile
                  v-if='detail.vendorId'
                  :data='detail.bill.coupon'
                  :title="$t('orders.bill.discount')"
                  type='coupon'
                />

              </template>
              <div class='h-divider'></div>
              <bill-price-tile
                :data='{price:detail.total_price}'
                :title="$t('table.total')"
                type='total'
              />
            </div>
          </div>

          <div class='order-note' v-if='detail.note'>
            <div class='text-reg-16 mb-2'>{{ $t('orders.customer_note') }}</div>
            <div class='text-reg-16 box danger'> {{ detail.note }}</div>
          </div>
          <div class='order-note' v-if='detail.images'>
            <div class='text-reg-16 mb-2'>{{ $t('orders.images') }}</div>
            <div class='images-box'>

              <a target='_blank' v-for='(image,index) in detail.images' :key='index' :href='image'>
                <img class='order-image' :src='image' alt=''>
              </a>

            </div>
          </div>
        </div>
      </b-card>
      <b-card class=' wameed-card'>
        <div class='d-flex justify-content-between'>

          <wameed-btn
            variant='main'
            size='md'
            :title="$t('common.check-price')"
            classes='text-book-18  rounded-12 w-unset px-4 m-0 main-btn'
            block
            @onClick='getOrderPrice'
          />
          <wameed-btn
            variant='gray'
            size='md'
            :title="$t('common.cancel')"
            classes='text-book-18  rounded-12 w-unset px-4 m-0 gray-btn'
            block
            @onClick='cancelOrderEdit'
          />
        </div>
      </b-card>
    </div>

    <product-detail-modal
      :visible='showProductModal'
      :order-id='detail.id'
      :new-order='true'
      :cart-product='productModalData'
      @close='showProductModal =false'
      @onSubmit='updateCartItems'
    />
    <price-detail
      :visible='showPriceDetailModal'
      @close='showPriceDetailModal =false'
      @onComplete='sendOrder'
    />

  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import BillPriceTile from '@/views/pages/orders/components/billPriceTile.vue';
import OrderTable from '@/views/pages/orders/components/orderTable.vue';
import ClientSection from '@/views/pages/orders/newOrder/sections/clientSection.vue';
import ProductsSection from '@/views/pages/orders/newOrder/sections/productsSection.vue';
import VendorSection from '@/views/pages/orders/newOrder/sections/vendorSection.vue';
import ProductDetailModal from '@/views/pages/orders/components/productDetailModal.vue';
import PriceDetail from '@/views/pages/orders/newOrder/priceDetail.vue';

export default {
  components: {
    PriceDetail,
    ProductDetailModal,
    VendorSection,
    ProductsSection,
    ClientSection,
    OrderTable, BillPriceTile,


    PageHeader
  },
  props: {
    path: Array
  },
  data() {
    return {
      productModalData: null,
      showProductModal: false,
      showPriceDetailModal: false,
      form: {
        user: null,
        vendor: null,
        order_time: ''
      }

    };
  },
  computed: {
    ...mapGetters({
      detail: 'admin/newOrder/getDetail'
    }),

    getVendorId() {
      if (!this.form.vendor) {
        return 0;
      }
      return this.form.vendor.id;
    },

    isVendor() {
      return this.$route.meta.type !== 'delivery';
    }
  },
  methods: {


    updateCartItems(data) {
      this.productModalData = null;

      this.$store.dispatch('admin/newOrder/updateCart', data);

      this.showProductModal = false;
    },
    //new methods
    async onClientChange(data) {
      this.form = {
        ...this.form,
        ...data
      };
      await this.calcDeliveryPrice();

    },


    async onVendorChange(data) {

      this.form = {
        ...this.form,
        ...data
      };
      this.$store.dispatch('admin/newOrder/newOrder');
      await this.$store.dispatch('admin/newOrder/getVendorProducts', this.form.vendor.id);
      await this.calcDeliveryPrice();
    },
    //end new methods

    async calcDeliveryPrice() {
      if (!this.form.vendor || !this.form.to_address) {
        return;
      }
      await this.$store.dispatch('admin/newOrder/getDeliveryPrice', {
        user: this.form.user,
        vendor: this.form.vendor,
        to_address: this.form.to_address,
        vehicle: this.form.vehicle
      });
    },

    cancelOrderEdit() {
      this.editCart = false;
    },

    getOrderPrice() {
      this.form.type = this.$route.meta.type;
      this.$store.dispatch('admin/newOrder/getOrderPrice', this.form).then(() => {
        this.showPriceDetailModal = true;
      });
      //   .then(() => {
      //     this.$store.dispatch('admin/newOrder/unsetCart');
      //     this.editCart = false;
      //   });
    },

    sendOrder() {
      let type = this.$route.meta.type;
      this.form.type = type;
      this.$store.dispatch('admin/newOrder/createOrder', this.form).then(() => {
        this.$store.dispatch('admin/newOrder/unsetCart');
        this.editCart = false;
        this.$router.push({
          name: 'orders-' + type,
          params: { lang: this.$i18n.locale, type: type }
        });
      });
    },


    showEditAddress(type) {
      this.showEditAddressModal = true;
      this.editAddressModalType = type;
    },

    pagePath() {
      if (this.path) {
        return this.path;
      }
      let type = this.$route.meta.type;
      return [{ title: this.$i18n.t('nav.orders.' + type), link: 'orders-' + type }];
    },
    showVendorDetail(id) {
      this.$router.push({
        name: 'users-vendors-detail',
        params: { lang: this.$i18n.locale, id }
      });
    },


    onCartEdit(data) {
      this.productModalData = data;
      let link = this.form.vendor.id + '/' + data.id;

      this.$store.dispatch('admin/newOrder/productDetail', link).then(() => {
          this.showProductModal = true;
        }
      );

    }


  },


  async beforeCreate() {
    //get users
    await this.$store.dispatch('admin/newOrder/newOrder');
    await this.$store.dispatch('admin/newOrder/getUsers');
    await this.$store.dispatch('admin/newOrder/getVehicleTypes');
    //get all vendors

    let type = this.$route.meta.type;
    if (type !== 'delivery') {
      await this.$store.dispatch('admin/newOrder/getVendors', { type: type });
    } else {
      await this.$store.dispatch('admin/newOrder/getOrderTypes');
    }
  }

};
</script>
