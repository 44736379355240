<template>
  <validation-observer
    ref='form'
    #default='{ invalid }'
    tag='form'
    class='repeater__form'
    style='overflow: visible'
  >
    <b-modal
      v-model='show'
      content-class='package-modal rounded-8'
      dialog-class='wameed-modal-md'
      no-fade
    >
      <template slot='modal-header-close'>
        <close-icon />
      </template>
      <template slot='modal-title'>
        <div class='d-block text-center'>
          <h3 class='text-reg-18'>
            {{ $t('orders.new.client') }}
          </h3>
        </div>
      </template>


      <b-row>

        <b-col md='12'>
          <text-input

            v-model='search'
            icon='search-icon'
            is-append
            input-classes='text-reg-14 '
            name='search'
            :placeholder="$t('common.search') + ' ...'"
            field-classes='mb-0 notification-filter-search '
          />
          <div class='w-table my-4 notification-filter-list'>
            <label class='custom-input input-bottom-border notification-filter-option'
                   v-for='(user, index) in filterUsers'
                   :key='index'
                   :class='[selectedUser!=null&& selectedUser.id === user.id ? "active" : ""]'
                   @click='selectUser(user)'
            >

              <name-tile
                :name='user.name'
                :phone='user.phone'
                :image='user.image'
              />

            </label>
          </div>
        </b-col>


      </b-row>


      <template slot='modal-footer'>
        <wameed-btn
          classes='  text-med-14 text-white mx-0 rounded-8'
          :title="modalData!=null ?$t('btn.update'):$t('btn.ok')"
          :disabled='invalid'
          type='submit'
          variant='main'
          @onClick='submitOrder()'
        />
        <wameed-btn
          classes='  text-med-14 text-font-secondary mx-0 rounded-8 '
          :title="$t('btn.cancel')"
          type='button'
          variant='gray'
          @onClick='closeModal()'
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WameedBtn, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { mapGetters } from 'vuex';
import NameTile from '@/components/nameTile.table.vue';


export default {
  components: {
    NameTile,
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    modalData: {
      type: Object,
      default: null
    }

  },

  data() {
    return {

      search: '',
      selectedUser: null,

      showSuccessModal: false
    };
  },
  computed: {
    ...mapGetters({
      getUsers: 'admin/newOrder/getUsers'
    }),
    toAgeValidations() {
      if (this.age.from) {
        return 'required|max_value:100|min_value:' + this.age.from;
      }
      return '';
    },
    fromAgeValidations() {
      if (this.age.to) {
        return 'required|max_value:' + this.age.to + '|min_value:0';
      }
      return '';
    },


    filterUsers() {
      if (this.search === '')
        return this.getUsers;

      return this.getUsers.filter(user => {
        return (user.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          || (user.phone.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          ;
      });
    },

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },

  created() {
    if (this.modalData != null) {
      this.form = this.modalData;
    }
  },
  methods: {


    selectUser(user) {
      this.selectedUser = user;
    },
    submitOrder() {
      if (this.selectedUser == null) {
        alert('قم بإختيار مستخدم');
        return;
      }
      this.$emit('onComplete', this.selectedUser);
      this.closeModal();

    },


    closeModal() {

      this.search = '';
      this.selectedUser = null;
      this.show = false;
    }
  }
};
</script>
