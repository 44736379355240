<template>
  <div>


    <div class='order-detail-table'>
      <b-card
        no-body
        class='wameed-card'
      >
        <b-card-header class='align-items-start tab-title'>
          <h5 class='text-med-18 text-font-main'>
            {{ $t('orders.vendor_detail') }}
          </h5>
        </b-card-header>

        <b-card-body
          class='position-relative justify-content-between px-5 p-6'
        >
          <b-col cols='12' md='4'>

            <div @click='onVendorChange' style='cursor: pointer;'>
              <wameed-dropdown
                style='pointer-events: none'
                v-model='form.vendor'
                :label="$t('orders.new.vendor')"
                :placeholder="$t('orders.new.vendor')"
                variant='disable'
                :items='[]'
                class='wameed_dropdown'
                title='name'
                rules='required'
                :no_options="$t('common.no_options')"
              />
            </div>
          </b-col>

        </b-card-body>
      </b-card>
    </div>


    <vendor-filter
      :visible='showVendorFilterModel'
      @close='showVendorFilterModel = false'
      @onComplete='onSelectVendor'
    />

  </div>

</template>

<script>

import VendorFilter from '@/views/pages/orders/newOrder/vendorFilter.vue';

export default {
  components: {
    VendorFilter
  },
  data() {
    return {

      form: {
        vendor: null
      },
      showVendorFilterModel: false
    };
  },


  methods: {


    //new methods
    onVendorChange() {
      this.showVendorFilterModel = true;
    },

    async onSelectVendor(data) {
      this.form.vendor = data;
      this.showVendorFilterModel = false;
      this.$emit('onChange', this.form);
    }


    //end new methods


  }


};
</script>
